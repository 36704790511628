import { IRegisterAndJoinOnboardingPoolReq } from "@dailyhuman/web-core/dist/api/onboarding/req";
import axios, { AxiosError, AxiosResponse } from "axios";
import { SnackBarContext } from "components/base/Alert/Alert";
import { useContext } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { apiRoute, commonHeaders } from "utils/api";

import { BrowserStorageItem, localStorageService } from "../utils/StorageHelper";

interface UserJoinReq {
  poolSlug?: string;
}

interface UserRegisterRes {
  id: string;
  accessToken: string;
}

function useRegisterUserToOnboardingPool() {
  const context = useContext(SnackBarContext);
  //const navigate = useNavigate();
  const { mutate: sendRegisterRequest, isLoading: isRegistrationRequestLoading } =
    useMutation(
      (data: Partial<IRegisterAndJoinOnboardingPoolReq>) =>
        axios.post<
          Partial<IRegisterAndJoinOnboardingPoolReq>,
          AxiosResponse<UserRegisterRes>
        >(`${apiRoute}/user/sign-up`, data, commonHeaders()),
      {
        onSuccess: data => {
          localStorageService.set(BrowserStorageItem.AUTH_TOKEN, data.data.accessToken);
          //navigate("/");
        },
        onError: (error: AxiosError) => {
          context?.setSnackBar({
            status: "failure",
            text: error.message,
          });
        },
      },
    );

  return {
    sendRegisterRequest,
    isRegistrationRequestLoading,
  };
}

function useJoinUserToOnboardingPool() {
  const context = useContext(SnackBarContext);
  const navigate = useNavigate();
  const { mutate: sendJoinRequest, isLoading: isJoinRequestLoading } = useMutation(
    (data: UserJoinReq) =>
      axios.post<IRegisterAndJoinOnboardingPoolReq, AxiosResponse<UserRegisterRes>>(
        `${apiRoute}/onboarding/pool/join`,
        data,
        commonHeaders(),
      ),
    {
      onSuccess: data => {
        //navigate("/");
      },
      onError: (error: AxiosError) => {
        // check if 409 error
        if (error.response?.status === 409) {
          navigate("/labs");
          return;
        }

        context?.setSnackBar({
          status: "failure",
          text: error.message,
        });
      },
    },
  );

  return {
    sendJoinRequest,
    isJoinRequestLoading,
  };
}

export { useJoinUserToOnboardingPool, useRegisterUserToOnboardingPool };
