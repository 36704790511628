import axios from "axios";
import { useQuery } from "react-query";

import { apiRoute, commonHeaders } from "../utils/api";

interface CheckUserReq {
  email?: string;
  phone?: string;
}

interface CheckUserRes {
  exists: boolean;
}

export default function useUserExists(params: CheckUserReq) {
  const {
    data: userExists,
    isFetching: isUserExistsLoading,
    refetch: userExistsRefetch,
  } = useQuery(["checkUserExists", params], () => getUserExists(params), {
    enabled: false,
  });

  return { userExists, isUserExistsLoading, userExistsRefetch };
}

export const getUserExists = (params: CheckUserReq) =>
  axios.get<CheckUserRes>(`${apiRoute}/user/check-exists`, {
    params,
    ...commonHeaders(),
  });
