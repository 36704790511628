import styled from "styled-components";

export const Card = styled.div<{ onClick?: () => void }>`
  background-color: #f9f7f4;
  border-radius: 1rem;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  min-width: 3rem;
  padding: 1rem;
`;

export default Card;
