import { IListJourneyV2ResItem } from "@dailyhuman/web-core/dist/api/game/res";
import Fade from "components/animations/Fade";
import Card from "components/base/Card";
import { useBackground } from "components/base/layout/Background";
import { ReactComponent as Labs } from "components/base/Menu/icons/Labs.svg";
import Quote from "components/base/Quote";
import Swiper from "components/base/Swiper/Swiper";
import Heading2 from "components/base/text/Heading2";
import Button from "components/form/Button";
import { useGetUserGames } from "hooks/useGetUserGames";
import useProfile from "hooks/user/useProfile";
import useWidth from "hooks/utils/useWidth";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import Media from "../base/Media";
import { ReactComponent as Bridge } from "./bridge.svg";
import { ReactComponent as BridgeSmall } from "./bridge-small.svg";
import { ReactComponent as Contact } from "./Contact.svg";
import IntersectionObserver from "./IntersectionObserver";
import JourneyItem from "./JourneyItem";

export type JourneyListProps = {
  journey?: IListJourneyV2ResItem[];
  isLoading?: boolean;
  onLoadMore?: () => void;
};

const BACKGROUNDS_COLORS = [
  "rgb(102,77,243)",
  "rgb(188,214,28)",
  "rgb(251,61,84)",
  "rgb(0,211,230)",
];

export default function JourneyList({
  journey,
  isLoading,
  onLoadMore,
}: JourneyListProps) {
  const [intersecting, setIntersecting] = useState(0);
  const [themeColor, setThemeColor] = useState(BACKGROUNDS_COLORS[0]);
  const { profile } = useProfile();
  const { games } = useGetUserGames();
  const isMobile = useWidth() < 840;

  const handleIntersect = (index: number) => {
    intersecting !== index && setIntersecting(index);
  };

  const backgroundColor = BACKGROUNDS_COLORS[intersecting % BACKGROUNDS_COLORS.length];

  useBackground(backgroundColor, true);

  //interpolate transition themeColor when backgroundColor change
  useEffect(() => {
    const isMobile = [/Android/i, /iPhone|iPad/i].some(regex =>
      regex.test(navigator.userAgent),
    );
    if (themeColor !== backgroundColor && isMobile) {
      const timeout = setTimeout(() => {
        const parseRGB = (color: string) => color.match(/\d+/g)?.map(Number);
        const themeRGB = parseRGB(themeColor);
        const backgroundRGB = parseRGB(backgroundColor);
        if (!themeRGB || !backgroundRGB) return;
        const newThemeColor = `rgb(${themeRGB
          .map((color, i) => Math.round(color + (backgroundRGB[i] - color) / 10))
          .join(",")})`;
        setThemeColor(newThemeColor);
      }, 30);
      return () => clearTimeout(timeout);
    }
  }, [themeColor, backgroundColor]);

  const isLabOpen = games?.data?.length;

  return (
    <JourneyListContainer className="JourneyList">
      <Helmet>
        <meta name="theme-color" content={themeColor} />
      </Helmet>
      <ContentContainer>
        {!!isLabOpen && !!journey?.length && (
          <JourneyItemWrapper>
            <JourneyItem
              headline="You have an open lab session ✅"
              message={
                <a href="/labs">
                  <Button icon={Labs}>Answer reflection</Button>
                </a>
              }
            />
          </JourneyItemWrapper>
        )}
        {journey?.map((cycle, l) => (
          <IntersectionObserver onIntersection={() => handleIntersect(l)} key={l}>
            <Fade delay={100}>
              {cycle.prompt && (
                <>
                  <JourneyItemWrapper even={false}>
                    <JourneyItem
                      headline={cycle.prompt.name}
                      message={
                        <>
                          {cycle?.prompt?.media && (
                            <Media uri={cycle?.prompt?.media || ""} />
                          )}
                          <br />
                          <p style={{ fontWeight: 700 }}>
                            <b>{cycle.prompt?.text}</b>
                          </p>
                        </>
                      }
                      sender={cycle.coach.name}
                      date={cycle.labStartAt}
                    />
                  </JourneyItemWrapper>
                  <StyledBridgeSmall />
                  <JourneyItemWrapper even>
                    <JourneyItem
                      headline={`Insights from ${cycle.coach.name}`}
                      message={cycle.prompt.defaultResponse}
                      sender={cycle.coach.name}
                      date={cycle.labStartAt}
                    />
                  </JourneyItemWrapper>
                  {cycle?.recPartner?.message && (
                    <>
                      <StyledBridge />
                      <JourneyItemWrapper>
                        <JourneyItem
                          headline={cycle.recPartner?.headline}
                          message={cycle.recPartner?.message}
                          sender={cycle.partner.name}
                          date={cycle.recPartner?.updatedAt}
                        />
                      </JourneyItemWrapper>
                    </>
                  )}
                  {cycle?.recUser?.message && (
                    <>
                      {cycle?.recPartner?.message ? (
                        <StyledBridgeSmall />
                      ) : (
                        <StyledBridge />
                      )}
                      <JourneyItemWrapper even>
                        <JourneyItem
                          headline={cycle.recUser?.headline}
                          message={cycle.recUser?.message}
                          sender={profile?.fullName}
                          date={cycle.recUser?.updatedAt}
                        />
                      </JourneyItemWrapper>
                    </>
                  )}
                  {cycle.recUser?.affirmation && (
                    <>
                      <StyledBridgeSmall />
                      <AffirmationQuote style={{ backgroundColor: "#f9f7f4" }}>
                        {cycle.recUser?.affirmation}
                      </AffirmationQuote>
                    </>
                  )}
                  {cycle.userAnalytics &&
                    cycle.userAnalytics.data.length > 0 &&
                    cycle.userAnalytics.data.some(i => i.insight) && (
                      <>
                        <StyledBridgeSmall />
                        <Swiper showNavigation={!isMobile}>
                          {cycle.userAnalytics?.data
                            .filter(i => i.insight)
                            .map((cycle, i) => (
                              <Card
                                style={{ width: 720, maxWidth: "100%", height: "100%" }}
                              >
                                <Heading2>{cycle.label}</Heading2>
                                {cycle.insight}
                              </Card>
                            ))}
                        </Swiper>
                      </>
                    )}
                </>
              )}
            </Fade>
          </IntersectionObserver>
        ))}
        {onLoadMore && <LoadMoreButton onClick={onLoadMore}>Load more</LoadMoreButton>}
        {!onLoadMore && !isLoading && Array.isArray(journey) && (
          <IntersectionObserver onIntersection={() => handleIntersect(journey.length)}>
            <Fade delay={100}>
              {journey.length === 0 && (
                <>
                  <JourneyItemWrapper>
                    <JourneyItem
                      headline="Let's get started!"
                      message={
                        <a href="/labs">
                          <Button icon={Labs}>Answer the prompt</Button>
                        </a>
                      }
                    />
                  </JourneyItemWrapper>
                  <StyledBridgeSmall />
                </>
              )}
              <JourneyItemWrapper>
                <JourneyItem
                  headline="Have you saved Dailyhuman’s contact card?"
                  message={
                    <a href="https://dh.live/save-contact">
                      <Button icon={Contact}>Save contact</Button>
                    </a>
                  }
                />
              </JourneyItemWrapper>
            </Fade>
          </IntersectionObserver>
        )}
      </ContentContainer>
    </JourneyListContainer>
  );
}

const AffirmationQuote = styled(Quote)`
  margin: 0 auto;
  max-width: calc(76vw + 2rem);
  width: 572px;
  & > svg {
    fill: white;
    left: -3.5rem;
    top: 0.5rem;
    width: 50px;
  }
  @media (max-width: 768px) {
    margin-left: 3rem;
  }
`;

const JourneyListContainer = styled.div`
  min-height: calc(100dvh - 60px);
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 6rem 0rem 2rem 0rem;
`;

const JourneyItemWrapper = styled.div<{ even?: boolean }>`
  margin: 0 auto;
  max-width: 95%;
  width: 640px;
  @media (min-width: 768px) {
    transform: ${({ even }) => (even ? "translateX(3rem)" : "translateX(-3rem)")};
  }
`;

const StyledBridge = styled(Bridge)`
  display: block;
  height: 200px;
  margin: -1px auto;
  max-width: calc(100% - 100px);
  width: 200px;
`;

export const StyledBridgeSmall = styled(BridgeSmall)`
  display: block;
  height: 36px;
  margin: -1px auto;
  max-width: calc(100% - 100px);
  width: 200px;
`;
const LoadMoreButton = styled.button`
  background: #f9f7f4;
  border: none;
  border-radius: 50%;
  color: #676464;
  cursor: pointer;
  display: block;
  font-weight: 800;
  height: 6rem;
  margin: 54px auto 0 auto;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s;
  width: 6rem;
  :hover {
    transform: translateY(-0.5rem);
  }
`;
