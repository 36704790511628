import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function Media({ uri }: { uri: string }) {
  // a media component that renders a image or a video
  // when image is provided, it will render the image on a square container
  // when video is provided, it will render the video on a square container and two buttons:
  // one to play/pause the video and another to download it

  const extention = uri.split(".").pop() || "unknown";
  const videoExts = ["mp4", "webm", "ogg"];
  const imageExts = ["jpg", "jpeg", "png", "webp"];

  if (videoExts.includes(extention)) {
    return <MediaVideo uri={uri} />;
  }
  if (imageExts.includes(extention)) {
    return <MediaImage uri={uri} />;
  }

  return <MediaInvalid />;
}

function MediaInvalid() {
  return (
    <div>
      <p>Invalid media type</p>
    </div>
  );
}

function MediaVideo({ uri }: { uri: string }) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loadedMetadata, setLoadedMetadata] = useState(true);

  useEffect(() => {
    const current = videoRef.current;
    const handleLoadedMetadata = () => setLoadedMetadata(true);
    current?.addEventListener("loadedmetadata", handleLoadedMetadata);
    return () => {
      current?.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, []);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = uri;
    a.download = uri.split("/").pop() || "video";
    a.click();
  };

  return (
    <VideoContainer loaded={loadedMetadata}>
      {!loadedMetadata && <Loading>Loading...</Loading>}
      <Video ref={videoRef} preload="metadata" onClick={handlePlayPause} playsInline>
        <source src={uri + "#t=0.001"} type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      <VideoButtons>
        <button onClick={handlePlayPause}>
          {!isPlaying ? (
            <svg width="48" height="48" viewBox="0 0 31 31">
              <circle cx="15.2884" cy="15.12" r="14.9166" fill="#664DF3" />
              <path
                d="M12.8826 9.82706V20.413L20.2553 15.12L12.8826 9.82706Z"
                fill="#664DF3"
                stroke="#F9F7F4"
                strokeWidth="1.92472"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg width="48" height="48" viewBox="0 0 54 54">
              <circle cx="27" cy="27" r="27" fill="#664DF3" />
              <path
                d="M21 35L21 19"
                stroke="#F9F7F4"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M33 35L33 19"
                stroke="#F9F7F4"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
        <button onClick={handleDownload}>
          <svg
            width="48"
            height="48"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="30.7596" cy="30.8942" r="30.1058" fill="#EEE7DF" />
            <path
              d="M41.7536 34.3366L41.7536 35.7786C41.7536 38.9642 39.1712 41.5466 35.9856 41.5466L24.2139 41.5466C21.0283 41.5466 18.4459 38.9642 18.4459 35.7786L18.4459 34.3366"
              stroke="#664DF3"
              strokeWidth="3.88462"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M30.7447 18.254V29.731M24.9177 26.126L30.7447 31.173L36.5716 26.126"
              stroke="#664DF3"
              strokeWidth="3.88462"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </VideoButtons>
    </VideoContainer>
  );
}

function MediaImage({ uri }: { uri: string }) {
  return (
    <div>
      <Image src={uri} alt="media" />
    </div>
  );
}

const Loading = styled.span`
  display: block;
`;

const Image = styled.img`
  border-radius: 1rem;
  height: auto;
  max-width: calc(100% - 4rem);
  object-fit: cover;
`;

const VideoButtons = styled.span`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & > button {
    padding: 0;
    border: none;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #f9f7f4;
`;

const VideoContainer = styled.span<{ loaded: boolean }>`
  align-items: flex-end;
  display: flex;
  gap: 1rem;
  & > ${VideoButtons} {
    opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  }
`;

const Video = styled.video`
  border-radius: 1rem;
  cursor: pointer;
  height: auto;
  max-width: calc(100% - 4rem);
`;
