import Fade from "components/animations/Fade";
import { useBackground } from "components/base/layout/Background";
import { Footer } from "components/Footer";
import ProfileForm from "components/user/ProfileForm";
import useProfile from "hooks/user/useProfile";
import useProfileUpdate from "hooks/user/useProfileUpdate";
import styled from "styled-components";

export const Profile = () => {
  useBackground("#f9f7f4");
  const { newProfile, isProfileLoading } = useProfile();
  const { updateProfile, isProfileUpdating, updateProfileErrors } = useProfileUpdate();

  if (!newProfile) return null;

  return (
    <PageWrapper delay={300}>
      <FormWrapper>
        <Box>
          <ProfileForm
            onSubmit={v => updateProfile(v)}
            initialValues={newProfile}
            loading={isProfileLoading || isProfileUpdating}
            enableReinitialize={true}
            initialErrors={updateProfileErrors}
          />
        </Box>
      </FormWrapper>
      <Footer />
    </PageWrapper>
  );
};

const PageWrapper = styled(Fade)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
`;

const FormWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 1rem;
`;

const Box = styled.div`
  background: #eee7df;
  border-radius: 36px;
  color: #241f18;
  max-width: 420px;
  padding: 1.5rem 1rem;
  width: fill-available;
  @media (min-width: 768px) {
    max-width: 720px;
  }
`;
