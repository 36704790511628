import { createRoot } from "react-dom/client";
import { sentryInit } from "utils/sentry";

import App from "./App";

sentryInit();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
