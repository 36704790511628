import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute, commonHeaders } from "utils/api";

export default function useLabs() {
  const { data, isFetching: isLabsLoading } = useQuery(["useLabs"], () =>
    axios.get<any>(`${apiRoute}/game/by-user`, {
      ...commonHeaders(),
    }),
  );

  const labs = data?.data?.data;

  return { labs, isLabsLoading };
}
