import "./assets/css/index.css";

import Background from "components/base/layout/Background";
import PageRouter from "components/PageRouter";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "utils/api";

function App() {
  return (
    <HelmetProvider>
      <GoogleAnalytics />
      <Background>
        <div className="App">
          <Router>
            <QueryClientProvider client={queryClient}>
              <PageRouter />
            </QueryClientProvider>
          </Router>
        </div>
      </Background>
    </HelmetProvider>
  );
}

const GoogleAnalytics = () => {
  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-XH5JVDYRLM"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-XH5JVDYRLM');`,
        }}
      />
    </>
  );
};

export default App;
