import CapacitySwiperItem from "components/analytics/CapacitySwiperItem";
import ConnectingDots from "components/analytics/ConnectingDots";
import RackStack from "components/analytics/RackStack";
import Fade from "components/animations/Fade";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Swiper from "components/base/Swiper/Swiper";
import Heading1 from "components/base/text/Heading1";
import Select from "components/form/Select";
import JourneyItem from "components/Journey/JourneyItem";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useAnalyticsGroupUser from "hooks/analytics/useAnalyticsGroupUser";
import useAnalyticsGroupUserLabs from "hooks/analytics/useAnalyticsGroupUserLabs";
import useProfile from "hooks/user/useProfile";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TCapacityHistoryItem } from "types/analytics";

export default function AnalyticsPage() {
  useBackground("rgb(102,77,243)", true);
  const { userid, groupid } = useParams<{ userid: string; groupid: string }>();
  const [searchParams] = useSearchParams();
  const gameid = searchParams.get("gameid") || undefined;

  const navigate = useNavigate();
  const navigateWithFade = useNavigateWithFade();
  const { profile, isProfileLoading } = useProfile();
  const { analyticsGroupUser } = useAnalyticsGroupUser(gameid, userid);
  const { labs, isLabsLoading } = useAnalyticsGroupUserLabs(userid);

  useEffect(() => {
    if (!gameid && labs?.length) {
      navigate(`/analytics/group/${groupid}/user/${userid}?gameid=${labs[0].id}`, {
        replace: true,
      });
    }
  }, [gameid, groupid, labs, navigate, userid]);

  if (isLabsLoading || isProfileLoading || !profile) return null;

  if (!labs?.length) return <EmptyContent />;

  const {
    user,
    linearChart,
    userResponse,
    coach,
    lab,
    connectingDots,
    rackStackValues,
  } = analyticsGroupUser || {};

  return (
    <Fade delay={100}>
      <LabSelect>
        <svg
          viewBox="0 0 64 65"
          fill="none"
          width={48}
          height={48}
          onClick={() => navigateWithFade(`/analytics/group/${groupid}`)}
          style={{ cursor: "pointer" }}
        >
          <rect y="0.5" width="64" height="64" rx="32" fill="#F9F7F4" />
          <path
            d="M36 20.5L24 32.5L36 44.5"
            stroke="#664DF3"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <Select
          value={gameid || ""}
          onChange={(e: any) =>
            navigate(
              `/analytics/group/${groupid}/user/${userid}?gameid=${e.target.value}`,
            )
          }
        >
          {labs?.sort().map((lab: any) => (
            <option key={lab.id} value={lab.id}>
              {lab.name}
            </option>
          ))}
        </Select>
      </LabSelect>
      {analyticsGroupUser && (
        <Page>
          <Section>
            <PageHeading>{user?.name}'s Analytics</PageHeading>
            <PageHeading>{lab?.prompt?.name}</PageHeading>

            <JourneyItem
              headline={lab?.prompt?.name}
              message={<p style={{ fontWeight: 700 }}>{lab?.prompt?.message}</p>}
              sender={coach?.name}
              date={lab?.startAt}
            />

            <JourneyItem
              headline={`Insights from ${coach?.name}`}
              message={lab?.prompt?.defaultResponse}
              sender={coach?.name}
              date={lab?.startAt}
            />
          </Section>

          <PageHeading>Insights based on your response</PageHeading>

          <Swiper>
            {linearChart?.capacities.map((capacity: any) => {
              const { label, score } = capacity;
              const { data, enabled: chartEnabled } = linearChart;
              return (
                <CapacitySwiperItem
                  data={parseLinearChartData(data, score)}
                  label={label}
                  chartEnabled={chartEnabled}
                />
              );
            })}
          </Swiper>

          <Section>
            <RackAndStackContainer>
              {connectingDots && <ConnectingDots data={connectingDots} />}
              {rackStackValues && <RackStack data={rackStackValues} />}
            </RackAndStackContainer>

            <Prompt>
              {userResponse?.message?.text && (
                <>
                  <JourneyItem
                    headline={userResponse?.headline}
                    message={userResponse?.message?.text}
                    sender={user?.name}
                    date={userResponse?.message?.date}
                  />
                </>
              )}
            </Prompt>
          </Section>
        </Page>
      )}
    </Fade>
  );
}

const LabSelect = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  left: 1rem;
  position: absolute;
  top: 1rem;
  z-index: 1;
  & > div {
    max-width: calc(100vw - 10rem);
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 6rem 0rem 4rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
  width: 720px;
`;

const PageHeading = styled(Heading1)`
  color: white;
  padding: 0 2rem;
  text-align: center;
`;

const RackAndStackContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 720px) {
    flex-direction: row;
    justify-content: center;
    & > div {
      width: 50%;
    }
  }
`;

const Prompt = styled.div`
  margin: 0 auto;
`;

function parseLinearChartData(data: any, label: string): TCapacityHistoryItem[] {
  return data?.map((item: any, i: number) => {
    return {
      date: item.date,
      value: item.scores[label].score,
      max: item.scores[label].max,
      min: item.scores[label].min,
      avg: item.scores[label].avg,
      insight: item.scores[label].insight,
    };
  });
}

// function parseWords(words: Record<string, number>): TWordsItem[] {
//   return Object.keys(words).map(key => ({
//     text: key,
//     value: words?.[key] || 0,
//   }));
// }
