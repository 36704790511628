import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { queryClient } from "utils/api";

import { BrowserStorageItem, localStorageService } from "../utils/StorageHelper";

export const GetTokenPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const redirectTo = searchParams.get("destination");
  const authToken = searchParams.get("token");

  useEffect(() => {
    if (authToken) {
      localStorageService.set(BrowserStorageItem.AUTH_TOKEN, authToken);
      queryClient.invalidateQueries("getProfile");
      window.location.href = redirectTo || "/";
    } else {
      localStorageService.remove(BrowserStorageItem.AUTH_TOKEN);
    }
  }, [authToken, navigate, redirectTo]);

  return null;
};
