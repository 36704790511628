import { initializePaddle, Paddle, PaddleEventData } from "@paddle/paddle-js";
import axios from "axios";
import Button from "components/form/Button";
import useOnboardingSubscription from "hooks/useOnboardingSubscription";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";

export const PaymentStep = () => {
  const { setMainStep } = useOnboardingContext() || {};

  const paddle = usePaddle({
    successUrl: `${window.location.href}?skipintro=true`,
    eventCallback: event => {
      console.warn(
        `Paddle event callback: ${event.type} ${event.name} ${JSON.stringify(event.data)} ${JSON.stringify(event.error)}`,
      );
    },
  });

  const { onboardingSubscription, onboardingSubscriptionError } =
    useOnboardingSubscription();
  const transactionId = onboardingSubscription?.paddle.transactionId;

  useEffect(() => {
    if (
      axios.isAxiosError(onboardingSubscriptionError) &&
      onboardingSubscriptionError.response?.status === 400
    ) {
      setMainStep?.("JoinLabStep");
    }
  }, [onboardingSubscriptionError, setMainStep]);

  if (!transactionId) {
    return null;
  }

  return (
    <Container>
      <div className="checkout-container" />
      <h2>Complete your registration by making a payment</h2>
      <Button
        onClick={() => {
          paddle?.Checkout.open({
            transactionId,
            settings: {
              displayMode: "overlay",
              locale: "en",
              showAddDiscounts: false,
              allowLogout: false,
            },
          });
        }}
      >
        Pay Now
      </Button>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  svg {
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 1rem;
    max-width: 14rem;
  }
`;

// never seller
type UsePaddleOptions = {
  eventCallback?: (e: PaddleEventData) => void;
  successUrl?: string;
};

const usePaddle = ({ eventCallback, successUrl }: UsePaddleOptions = {}) => {
  const [paddle, setPaddle] = useState<Paddle>();
  const token = process.env.REACT_APP_PADDLE_CLIENT_SIDE_TOKEN || "";

  useEffect(() => {
    initializePaddle({
      eventCallback,
      token,
      environment: token.startsWith("test") ? "sandbox" : "production",
      checkout: {
        settings: {
          successUrl,
        },
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [token, eventCallback, successUrl]);

  return paddle;
};
