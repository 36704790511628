import { useRive } from "@rive-app/react-canvas";

import AnimationFile from "./Loading.riv";

const stateMachine = "State Machine 1";

export default function Loading() {
  const { RiveComponent } = useRive({
    src: AnimationFile,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  return <RiveComponent style={{ width: "320px", maxWidth: "100%", aspectRatio: 1 }} />;
}
