import axios from "axios";
import { useQuery } from "react-query";

import { apiRoute, commonHeaders } from "../utils/api";
import { Pagination } from "../utils/commonTypes";

export enum GameStatus {
  completed = "COMPLETED",
  pending = "PENDING",
}

export type GamesData = {
  data: Buckets[];
  pagination: Pagination;
};

export type Buckets = {
  createdAt: string;
  cycleId: string;
  forceCompleteAt: string;
  partner: Partner;
  prompt: Prompt;
  user: User;
  game: { id: string; name: string };
};

type Partner = {
  bucketStatus: GameStatus;
  id: string;
  msgId: string | null;
  name: string;
  image?: string;
};

type Prompt = {
  id: string;
  name: string;
  media?: string;
  text: string;
  greeting: string;
};

type User = {
  bucketStatus: GameStatus;
  msgId: string;
  image?: string;
};

function useGetUserGames() {
  const { data: games, isFetching: isGamesLoading } = useQuery(["getUserGames"], () =>
    axios.get<GamesData>(`${apiRoute}/game/user-active-bucket`, {
      params: { page: 1, limit: 10, sort: "-createdAt" },
      ...commonHeaders(),
    }),
  );

  return { games: games?.data, isGamesLoading };
}

export { useGetUserGames };
