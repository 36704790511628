import { IGetUserAnalyticsResV2 } from "@dailyhuman/web-core/dist/api/game/res";
import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute, commonHeaders } from "utils/api";

export default function useAnalyticsLab(labId?: string) {
  const { data, isFetching: isAnalyticsLabLoading } = useQuery(
    ["useAnalyticsLab", labId],
    () =>
      axios.get<IGetUserAnalyticsResV2>(
        `${apiRoute}/recognition/analytics/game/${labId}`,
        {
          ...commonHeaders(),
        },
      ),
    {
      enabled: !!labId,
    },
  );
  const analyticsLab = data?.data?.result;

  return { analyticsLab, isAnalyticsLabLoading };
}
