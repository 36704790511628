import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute, commonHeaders } from "utils/api";

export type PoolInfo = {
  id: string;
  name: string;
  shortUrl: string;
  size?: number;
  members: number;
  createdAt: string;
  updatedAt: string;
  welcomeMessage: string;
  isPaid: boolean;
};

export const useGetOnboardingGroupInfo = (poolSlug?: string) => {
  const { data: poolInfo, isFetching: isPoolInfoLoading } = useQuery(
    ["getOnboardingGroupInfo", poolSlug],
    () =>
      axios.get<PoolInfo>(
        `${apiRoute}/onboarding/pool/slug/${poolSlug}`,
        commonHeaders(),
      ),
    {
      enabled: !!poolSlug,
    },
  );

  return { poolInfo, isPoolInfoLoading };
};
