import Button from "components/form/Button";
import { useGetOnboardingGroupInfo } from "hooks/useGetOnboardingGroupInfo";
import { useJoinUserToOnboardingPool } from "hooks/useRegisterUser";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as JoinImage } from "../components/JoinImage.svg";

export const JoinLabStep = () => {
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);
  const { sendJoinRequest } = useJoinUserToOnboardingPool();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    if (!poolInfo?.data.isPaid) {
      sendJoinRequest({ poolSlug });
    }
    setIsLoading(true);
    setTimeout(() => navigate("/labs"), 2000);
  };

  return (
    <Container>
      <div />
      <div>
        <JoinImage />
        <h2>You're in. Dive into your mindset practice!</h2>
      </div>
      <Button type={"button"} onClick={handleClick} loading={isLoading}>
        Continue
      </Button>
    </Container>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: center;
  svg {
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 1rem;
    max-width: 14rem;
  }
`;
