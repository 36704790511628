import axios, { AxiosHeaders, AxiosResponse } from "axios";
import { useMutation } from "react-query";

import { apiRoute, commonHeaders } from "../utils/api";

type EmailVerificationReqType = {
  code: string;
};

function useConfirmTransport() {
  const {
    mutate: sendConfirmationRequest,
    isLoading: isConfirmationRequestLoading,
    data: confirmationResponse,
    status,
    reset,
  } = useMutation(["confirmVerification"], (data: EmailVerificationReqType) =>
    axios
      .post<EmailVerificationReqType, { data: { confirmed: boolean } }>(
        `${apiRoute}/user/verification/confirm`,
        data,
        commonHeaders(),
      )
      .catch(() => {
        const dataOnError: AxiosResponse = {
          data: {
            confirmed: false,
          },
          status: 200,
          statusText: "Ok",
          headers: commonHeaders().headers,
          config: {
            headers: {} as AxiosHeaders,
          },
        };
        return dataOnError;
      }),
  );

  return {
    sendConfirmationRequest,
    isConfirmationRequestLoading,
    confirmationResponse,
    status,
    reset,
  };
}

export { useConfirmTransport };
