import Card from "components/base/Card";
import { ComponentProps } from "react";
import styled from "styled-components";
import { TWords } from "types/analytics";

interface WordsProps extends ComponentProps<typeof Card> {
  words: TWords;
}

export default function Words({ words, ...props }: WordsProps) {
  const { count, insight } = words;
  return (
    <Card title="Word Bias Pulse Map" subtitle="What are your words saying?" {...props}>
      <Container>
        {count.map((word, i) => (
          <Word>{word.text}</Word>
        ))}
        {insight && (
          <>
            <hr />
            <>
              <>Word Bias Insight</>
              {insight}
            </>
          </>
        )}
      </Container>
    </Card>
  );
}

const Container = styled.div`
  max-width: 100%;
  min-width: 260px;
  width: 300px;
`;

const Word = styled.div`
  background-color: #664df3;
  color: #f9f7f4;
  display: inline-block;
  font-size: 0.9rem;
  margin: 0.25rem;
  padding: 0.5rem;
  text-transform: uppercase;
`;
