import ConnectingDots from "components/analytics/ConnectingDots";
import RackStack from "components/analytics/RackStack";
import Words from "components/analytics/Words";
import Fade from "components/animations/Fade";
import Switch from "components/animations/Switch";
import Card from "components/base/Card";
import EmptyContent from "components/base/EmptyContent";
import { useBackground } from "components/base/layout/Background";
import Swiper from "components/base/Swiper/Swiper";
import Heading1 from "components/base/text/Heading1";
import Heading2 from "components/base/text/Heading2";
import CompareChart from "components/chart/CompareChart";
import Select from "components/form/Select";
import { useNavigateWithFade } from "components/PageRouter/PageRouter";
import useAnalyticsGroup from "hooks/analytics/useAnalyticsGroup";
import useAnalyticsGroupPrompts from "hooks/analytics/useAnalyticsGroupPrompts";
import useAnalyticsGroupUsers from "hooks/analytics/useAnalyticsGroupUsers";
import useGetCoachGroups, { CoachGroups } from "hooks/useGetCoachGroups";
import { ChangeEvent, useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function NewAnalyticsGroup() {
  useBackground("rgb(102,77,243)", true);
  const { groupid: groupId } = useParams<{ groupid: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateWithFade = useNavigateWithFade();

  const { analyticsGroupPrompts, isAnalyticsGroupUsersLoading } =
    useAnalyticsGroupPrompts(groupId || "");
  const promptSetId = searchParams.get("promptsetid") || undefined;

  const { coachGroups, isCoachGroupsLoading } = useGetCoachGroups();
  const { analyticsGroup } = useAnalyticsGroup(groupId, promptSetId);
  const { analyticsGroupUsers } = useAnalyticsGroupUsers({ groupId, promptSetId });

  useEffect(() => {
    if (!groupId && coachGroups?.length) {
      navigate(`/analytics/group/${coachGroups[0].id}`, { replace: true });
    }
    if (groupId && !promptSetId && analyticsGroupPrompts?.length) {
      navigate(
        `/analytics/group/${groupId}?promptsetid=${analyticsGroupPrompts?.[0].id}`,
        { replace: true },
      );
    }
  }, [analyticsGroupPrompts, coachGroups, groupId, navigate, promptSetId]);

  if (isCoachGroupsLoading) return null;

  if (!isCoachGroupsLoading && !coachGroups?.length) return <EmptyContent />;

  const promptSet = analyticsGroupPrompts?.find(({ id }) => id === promptSetId);

  const { group, analytics } = analyticsGroup || {};
  const { words, rackStack, connectingDots } = analytics || {};

  const handleGroupSelectorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    navigate(`/analytics/group/${e.target.value}`);
    e.stopPropagation();
  };

  const handlePromptSetSelectorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    navigate(`/analytics/group/${groupId}?promptsetid=${e.target.value}`);
    e.stopPropagation();
  };

  return (
    <Fade delay={200}>
      <GroupsSelectorContainer>
        <Select label="Groups" onChange={handleGroupSelectorChange} value={groupId}>
          {coachGroups &&
            flattenCoachGroups(coachGroups).map?.(groupItem => {
              return (
                <option key={groupItem.id} id={groupItem.id} value={groupItem.id}>
                  {groupItem.name}
                </option>
              );
            })}
        </Select>
        <Select
          label="Prompt Sets"
          onChange={handlePromptSetSelectorChange}
          value={promptSetId || ""}
          loading={isAnalyticsGroupUsersLoading}
        >
          {analyticsGroupPrompts?.map?.(prompt => {
            return (
              <option key={prompt.id} id={prompt.id} value={prompt.id}>
                {prompt.name}
              </option>
            );
          })}
        </Select>
      </GroupsSelectorContainer>
      <Switch renderKey={groupId + "-" + promptSetId}>
        {group && (
          <Page>
            <Header>
              {promptSet?.name} {">"} {group?.name}
              <Heading1>Group Analytics</Heading1>
            </Header>
            {analyticsGroup?.scores && (
              <GroupAverageCard>
                <Heading2>Group Average</Heading2>
                <br />
                <CompareChart data={analyticsGroup?.scores} />
              </GroupAverageCard>
            )}
            <Swiper
              initialIndex={
                ([words, rackStack, connectingDots].filter(Boolean).length - 1) / 2
              }
            >
              {words && (
                <Words
                  words={words}
                  subtitle={`What are ${group?.name} words saying?`}
                />
              )}
              {rackStack && <RackStack data={rackStack} />}
              {connectingDots && <ConnectingDots data={connectingDots} />}
            </Swiper>
            {analyticsGroupUsers?.users?.length && (
              <>
                <Header>
                  <Heading1 id="members">Player Breakdown</Heading1>
                </Header>
                <Limiter>
                  <UserListContainer>
                    {analyticsGroupUsers?.users?.map(
                      ({ analytics, user }) =>
                        analytics.capacity?.length && (
                          <Card
                            onClick={() =>
                              navigateWithFade(location.pathname + "/user/" + user.id)
                            }
                          >
                            <Heading2>{user.name}</Heading2>
                            <br />
                            <CompareChart
                              mainTitle="User score"
                              compareTitle="Group average"
                              data={analytics.capacity?.map(({ label, value }) => ({
                                label,
                                value: value || 0,
                                compare: analyticsGroup?.scores?.find(
                                  score => score.label === label,
                                )?.value,
                              }))}
                            />
                          </Card>
                        ),
                    )}
                  </UserListContainer>
                </Limiter>
              </>
            )}
          </Page>
        )}
      </Switch>
    </Fade>
  );
}

function flattenCoachGroups(
  groups: CoachGroups,
  level: number = 0,
): { id: string; name: string }[] {
  let result: { id: string; name: string }[] = [];

  groups.forEach(group => {
    // Add current group with indentation
    result.push({
      id: group.id,
      name: `${"\u00A0".repeat(level * 4)}${group.name}`,
    });

    // Recursively process children
    if (group.children && group.children.length > 0) {
      result = result.concat(flattenCoachGroups(group.children, level + 1));
    }
  });

  return result;
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 0;
`;

const GroupAverageCard = styled(Card)`
  margin: 0 auto;
  max-width: calc(100vw - 4rem);
  width 580px;
`;

const Header = styled.div`
  color: white;
  text-align: center;
`;

const UserListContainer = styled.div`
  display: grid;
  flex: 1;
  flex-wrap: wrap;
  gap: 1rem;
  grid-template-columns: 1fr;
  margin-bottom: 1rem;
  @media (min-width: 540px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 880px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const GroupsSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  padding: 1.25rem 1rem;
  width: calc(100vw - 5rem);
  @media (min-width: 640px) {
    flex-direction: row;
    & > div {
      width: calc(50% - 2rem);
    }
  }
`;

const Limiter = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
  width: 960px;
`;
