import useLoaded from "hooks/utils/useLoaded";
import styled from "styled-components";

const defaultData = [
  {
    label: "grit",
    value: 86,
  },
  {
    label: "mindfulness",
    value: 85,
  },
  {
    label: "curiosity",
    value: 92,
  },
  {
    label: "heart",
    value: 20,
  },
  {
    label: "storytelling",
    value: 54,
  },
];

type CompareChartProps = {
  mainTitle?: string;
  compareTitle?: string;
  data?: { label: string; value: number; compare?: number }[];
};

export default function CompareChart({
  mainTitle,
  compareTitle,
  data = defaultData,
}: CompareChartProps) {
  const loaded = useLoaded();
  return (
    <Container>
      {mainTitle && (
        <Title>
          <div>
            <b>{mainTitle}</b> {compareTitle && "vs"}
          </div>
          <div>
            <span>{compareTitle}</span>
          </div>
        </Title>
      )}
      {data.map((item, index) => {
        const { label, value, compare } = item;
        const transitionDelay = `${index * 0.1 + 0.4}s`;

        return (
          <ItemContainer>
            <Label>{label}</Label>
            <BarContainer>
              <BarMain value={loaded ? value : 0} style={{ transitionDelay }} />
              <ValueMain>{value.toFixed(0)}%</ValueMain>
            </BarContainer>
            {compare && (
              <BarContainer>
                <BarCompare value={loaded ? compare : 0} style={{ transitionDelay }} />
                <ValueCompare>{compare.toFixed(0)}%</ValueCompare>
              </BarContainer>
            )}
          </ItemContainer>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  border-left: 2px solid #664df3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1;
  padding: 0.25rem 0;
`;

const Title = styled.div`
  margin-left: 0.5rem;
  b {
    color: #664df3;
    font-weight: bold;
  }
  span {
    color: #ab997e;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  color: #664df3;
  margin-left: 0.5rem;
`;

const BarContainer = styled.div`
  align-items: center;
  display: flex;
`;

const ValueMain = styled.span`
  color: #664df3;
  font-size: 1rem;
  font-weight: bold;
`;

const ValueCompare = styled.span`
  color: #ab997e;
  font-size: 0.8rem;
  font-weight: bold;
`;

const Bar = styled.span<{ value: number }>`
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-right: 0.5rem;
  transition: width 0.6s 0.3s;
  width: calc(${props => props.value}% - ${props => props.value / 300}rem);
`;

const BarMain = styled(Bar)`
  background: #664df3;
  height: 0.6rem;
`;

const BarCompare = styled(Bar)`
  background: #ab997e;
  height: 0.4rem;
  transition-delay: 0.4s;
`;
