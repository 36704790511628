import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useState } from "react";

import SendButtonRiv from "./SendButton.riv";

const stateMachine = "Main Machine";

type SendButtonProps = {
  isDisabled?: boolean;
};

export default function SendButton({ isDisabled }: SendButtonProps) {
  const { rive, RiveComponent } = useRive({
    src: SendButtonRiv,
    stateMachines: stateMachine,
    autoplay: true,
    shouldDisableRiveListeners: true,
  });

  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  const disabled = useStateMachineInput(rive, stateMachine, "Disabled");
  const hover = useStateMachineInput(rive, stateMachine, "Hover");
  const pressed = useStateMachineInput(rive, stateMachine, "Pressed");

  if (disabled && hover && pressed) {
    disabled.value = !!isDisabled;
    hover.value = !isDisabled && isHovered;
    pressed.value = isPressed;
  }

  return (
    <RiveComponent
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onTouchStart={() => {
        setIsHovered(true);
        setIsPressed(true);
      }}
      onTouchEnd={() => {
        setIsHovered(false);
        setIsPressed(false);
      }}
    />
  );
}
