import Fade from "components/animations/Fade";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { queryClient } from "utils/api";
import { BrowserStorageItem, localStorageService } from "utils/StorageHelper";

import MenuCompact from "./MenuButton";
import MenuDrawer from "./MenuDrawer";

export default function Menu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    setShowMenu(false);
  }, [location]);

  const clearCookies = () => {
    localStorageService.remove(BrowserStorageItem.AUTH_TOKEN);
    navigate("/login");
    queryClient.clear();
  };

  return (
    <>
      <Background onClick={() => setShowMenu(false)} visible={showMenu} />
      <MenuDrawer logout={clearCookies} isVisible={showMenu} />
      <Fade delay={600}>
        <MenuCompact onClick={() => setShowMenu(!showMenu)} open={showMenu} />
      </Fade>
    </>
  );
}

const Background = styled.div<{ visible: boolean }>`
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
  @media (min-width: 768px) {
    backdrop-filter: blur(2px);
  }
`;
