import { IListGameChatHistoryRes } from "@dailyhuman/web-core/dist/api/game/res";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";

import { apiRoute, commonHeaders, queryClient } from "../utils/api";

function useGetGameMessages({
  gameCycleId,
  isEnabled,
}: {
  gameCycleId?: string;
  isEnabled: boolean;
}) {
  const {
    data: messages,
    isFetching: isMessagesLoading,
    error: messagingListError,
    isError: isMessagingListError,
  } = useQuery(
    ["getGameMessages"],
    ({ signal }) => {
      return axios.get<IListGameChatHistoryRes>(
        `${apiRoute}/game/cycle/${gameCycleId}/chat-history?waitForProcessing=false`,
        {
          ...commonHeaders(),
          signal,
        },
      );
    },
    {
      enabled: isEnabled && !!gameCycleId,
      refetchInterval: 3000,
      async onSuccess({ data }) {
        if (data?.meta?.dataWaitTimeout) {
          await queryClient.refetchQueries({ queryKey: ["getGameMessages"] });
        }
      },
      onError(error: AxiosError) {
        return error;
      },
    },
  );

  return {
    messages: messages?.data,
    isMessagesLoading,
    messagingListError,
    isMessagingListError,
  };
}

export { useGetGameMessages };
