import { IListJourneyV2Res } from "@dailyhuman/web-core/dist/api/game/res";
import axios from "axios";
import { useQuery } from "react-query";

import { apiRoute, commonHeaders } from "../utils/api";
import { Pagination } from "../utils/commonTypes";

export type JourneyResponse = {
  data: JourneyCycle[];
  pagination: Pagination;
};

export type JourneyCycle = {
  intro: string;
  summary?: string;
  recognitions: Recognition[];
  labCloseAt: string;
  labStartAt: string;
  cycleId: string;
  coach: {
    id: string;
    name: string;
    image: string;
  };
  prompt: {
    defaultResponse: string;
    greeting?: string;
    intro: string;
    media?: string;
    name: string;
    text: string;
  };
  game: {
    id: string;
    name: string;
  };
  recPartner?: {
    affirmation: string;
    createdAt: string;
    headline: string;
    id: string;
    message: string;
    status: string;
    updatedAt: string;
  };
  recUser?: {
    affirmation: string;
    createdAt: string;
    headline: string;
    id: string;
    message: string;
    status: string;
    updatedAt: string;
  };
  partner: {
    id: string;
    name: string;
    image: string;
  };
};

export type Recognition = {
  id: string;
  createdAt: string;
  message: string;
  updatedAt: string;
  headline: string;
  sender: {
    id: string;
    name: string;
    image: string;
  };
};

type UserJourneyProps = {
  userId?: string;
  page?: number;
};

function useGetUserJourney({ userId, page = 1 }: UserJourneyProps) {
  const params: { limit: number; page?: number; partnerId?: string } = {
    limit: 20,
    page: page,
  };

  if (userId) {
    params.partnerId = userId;
  }

  const { data, isFetching: isJourneyLoading } = useQuery(
    ["getUserJourney", userId, page],
    () =>
      axios.get<IListJourneyV2Res>(
        `${apiRoute?.slice(0, -3)}/v2/recognition/`,

        {
          params,
          ...commonHeaders(),
        },
      ),
  );

  const journey = data?.data;

  return { journey, isJourneyLoading };
}

export { useGetUserJourney };
