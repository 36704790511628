import Small from "components/base/text/Small";
import Button from "components/form/Button";
import ButtonGroup from "components/form/ButtonGroup";
import Field from "components/form/Field";
import Input from "components/form/Input";
import TimezoneSelect from "components/form/TimezoneSelect";
import { useGetOnboardingGroupInfo } from "hooks/useGetOnboardingGroupInfo";
import useIsAuth from "hooks/user/useIsAuth";
import useKeyAction from "hooks/utils/useKeyAction";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useOnboardingContext } from "../components/OnboardingUserFlow";
import { useRegisterFormContext } from "../components/RegisterForm";

export const NameAndTimezoneStep = () => {
  const isAuth = useIsAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { errors, handleSubmit } = useRegisterFormContext();
  const { setMainStep } = useOnboardingContext() || {};
  const { poolSlug } = useParams<{ poolSlug: string }>();
  const { poolInfo } = useGetOnboardingGroupInfo(poolSlug);

  const isDisabled = !!errors.firstName || !!errors.lastName || !!errors.timezone;

  const handleClick = useKeyAction({
    key: "Enter",
    action: async e => {
      if (isDisabled) return;
      handleSubmit();
      setIsLoading(true);
      e.preventDefault();
    },
  });

  useEffect(() => {
    const nextStep = poolInfo?.data.isPaid ? "PaymentStep" : "JoinLabStep";
    if (isAuth) setMainStep?.(nextStep);
  }, [isAuth, poolInfo?.data.isPaid, setMainStep]);

  return (
    <NameAndTimezoneInputStepContainer>
      <div>
        <h2>Enter name</h2>
        <FormGroup>
          <div>
            <Field name="firstName" as={Input} label="First name" />
          </div>
          <div>
            <Field name="lastName" as={Input} label="* Last name" />
          </div>
        </FormGroup>
        <Small>
          * Note: For privacy, consider using only the first letter of your last name{" "}
          <br />
          <br />
        </Small>
        <Hidden>
          <Field name="timezone" as={TimezoneSelect} label="Timezone" />
        </Hidden>
      </div>
      <ButtonGroup>
        <Button
          type={"button"}
          disabled={isDisabled}
          onClick={handleClick}
          loading={isLoading}
        >
          Continue
        </Button>
      </ButtonGroup>
    </NameAndTimezoneInputStepContainer>
  );
};

const NameAndTimezoneInputStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  h2 {
    font-size: 1.375rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const FormGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  & > div {
    flex: 1;
    max-width: calc(50% - 0.5rem);
  }
`;

const Hidden = styled.div`
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
`;
