import Fade from "components/animations/Fade";
import useWidth from "hooks/utils/useWidth";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import SwiperItem from "./SwiperItem";

type SwiperProps = {
  children?: React.ReactNode;
  showNavigation?: boolean;
  initialIndex?: number;
};

export default function Swiper({
  children,
  showNavigation,
  initialIndex,
}: SwiperProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useWidth(containerRef);

  const scrollToIndex = (index: number) => {
    // get the child position ignoting the scroll
    const containerScroll = containerRef.current?.scrollLeft || 0;
    const childPosition =
      containerRef.current?.children[index + 1]?.getBoundingClientRect();
    const childPositionLeft = childPosition?.left || 0;
    const childScroll = childPositionLeft - containerWidth / 2 + containerScroll;
    const childWidth = childPosition?.width || 0;
    // scroll to the child position
    containerRef.current?.scrollTo({
      left: childScroll + childWidth / 2,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    //navigate to initis index
    scrollToIndex(initialIndex || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndex]);

  useEffect(() => {
    // monitor container scroll to get current centered child index
    const element = containerRef.current;
    const handleScroll = () => {
      const containerScroll = element?.scrollLeft || 0;
      const containerWidth = element?.offsetWidth || 0;
      const children = element?.children || [];
      const childrenArray = Array.from(children || []);
      const childrenOffset = childrenArray.map((child, index) =>
        childrenArray
          .slice(0, index)
          .reduce((acc, child) => acc + (child as HTMLElement).offsetWidth, 0),
      );
      const childrenCenter = childrenOffset.map(
        (offset, index) =>
          offset + (childrenArray[index] as HTMLElement).offsetWidth / 2,
      );
      const childrenIndex = childrenCenter.findIndex(
        center => center > containerScroll + containerWidth / 2,
      );
      setActiveIndex(childrenIndex - 2);
    };
    element?.addEventListener("scroll", handleScroll);
    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  const activeChild = Array.from(containerRef.current?.children || [])[activeIndex + 1];
  const [activeChildWidth, setActiveChildWidth] = useState(
    (activeChild as HTMLElement)?.clientWidth || 0,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const activeChild = Array.from(containerRef.current?.children || [])[
        activeIndex + 1
      ];
      setActiveChildWidth((activeChild as HTMLElement)?.clientWidth || 0);
    }, 1000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  if (React.Children.toArray(children).every(child => !child)) return null;

  return (
    <Fade delay={1000}>
      <Container>
        <SwiperContainer ref={containerRef} showNavigation={showNavigation}>
          <div style={{ minWidth: containerWidth / 2 }} />
          {React.Children.map(children, (child, index) => {
            if (!child) return null;
            return (
              <SwiperItem
                key={index}
                containerRef={containerRef}
                active={index > activeIndex - 2 && index < activeIndex + 2}
              >
                {child}
              </SwiperItem>
            );
          })}
          <div style={{ minWidth: containerWidth / 2 }} />
        </SwiperContainer>
        {showNavigation && (
          <>
            <PagerContainer>
              {React.Children.map(children, (child, index) => {
                if (!child) return null;
                return (
                  <Pager
                    onClick={() => scrollToIndex(index)}
                    active={activeIndex === index}
                  >
                    {index + 1}
                  </Pager>
                );
              })}
            </PagerContainer>
            <ArrowContainer>
              <Arrow
                onClick={() => scrollToIndex(activeIndex - 1)}
                style={{ left: `calc(50% - ${activeChildWidth / 2 + 72}px)` }}
                disabled={activeIndex === 0}
              >
                <svg viewBox="0 0 64 65" fill="none">
                  <rect y="0.5" width="64" height="64" rx="32" fill="#F9F7F4" />
                  <path
                    d="M36 20.5L24 32.5L36 44.5"
                    stroke="#664DF3"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Arrow>
              <Arrow
                onClick={() => scrollToIndex(activeIndex + 1)}
                style={{ right: `calc(50% - ${activeChildWidth / 2 + 72}px)` }}
                disabled={
                  activeIndex ===
                  Array.from(containerRef.current?.children || []).length - 3
                }
              >
                <svg viewBox="0 0 64 65" fill="none">
                  <rect y="0.5" width="64" height="64" rx="32" fill="#F9F7F4" />
                  <path
                    d="M36 20.5L24 32.5L36 44.5"
                    stroke="#664DF3"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Arrow>
            </ArrowContainer>
          </>
        )}
      </Container>
    </Fade>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SwiperContainer = styled.div<{ showNavigation?: boolean }>`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  & > div {
    ${props => !props.showNavigation && "opacity: 1 !important;"}
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

const PagerContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Pager = styled.button<{ active?: boolean }>`
  background: white;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 0.1rem;
  height: 1rem;
  opacity: ${props => (props.active ? 1 : 0.6)};
  padding: 0;
  text-indent: -9999px;
  transition: background 0.3s;
  width: 1rem;
`;

const ArrowContainer = styled.div`
  height: 0;
  width: 0;
`;

const Arrow = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 3rem;
  padding: 0;
  position: absolute;
  top: calc(50% - 1.5rem);
  transform: translateY(-50%);
  transition: all 0.4s;
  width: 3rem;
  z-index: 1;
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &:last-child {
    transform: rotate(180deg) translateY(50%);
  }
`;
