import { useEffect } from "react";

interface useEventProps {
  type: string;
  handler: (e: Event) => void;
}

export default function useEvent({ type, handler }: useEventProps) {
  return useEffect(() => {
    window.addEventListener(type, handler);
    return () => window.removeEventListener(type, handler);
  }, [type, handler]);
}
