import { useFormikContext } from "formik";
import { ComponentProps } from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";

export interface InputProps<T> extends ComponentProps<typeof StyledInput> {
  name?: keyof T;
}

export default function Input<T>({ className, ...props }: InputProps<T>) {
  const { errors, touched } = useFormikContext<T>() || {};
  const error = props.name && touched?.[props.name] && errors[props.name];

  return (
    <div className={className}>
      <StyledInput type="text" mask={false} guide={false} {...props} error={error} />
    </div>
  );
}

const StyledInput = styled(MaskedInput)<{ error?: boolean }>`
  background: #f9f7f4;
  border: none;
  border-radius: 99px;
  box-sizing: content-box;
  color: #241f18;
  font-family: "Matter", sans-serif;
  height: 1.25rem;
  line-height: 1rem;
  outline: 2px solid ${({ error }) => (error ? "#f44336" : "transparent")};
  padding: 0.75rem 1rem;
  transition: all 0.2s ease-in-out;
  width: fill-available;
  &:focus {
    outline-color: ${({ error }) => (error ? "#f44336" : "#664df3")};
  }
`;
