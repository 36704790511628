import Select from "components/form/Select";
import { useFormikContext } from "formik";
import { ComponentProps } from "react";
import styled from "styled-components";

import { ReactComponent as Target } from "./Target.svg";

declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";

  function supportedValuesOf(input: Key): string[];
  function DateTimeFormat(): {
    resolvedOptions(): {
      timeZone: string;
    };
  };
}

const timezones = Intl.supportedValuesOf("timeZone");

export default function TimezoneSelect(props: ComponentProps<typeof Select>) {
  const { setFieldValue } = useFormikContext();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { name } = props;

  return (
    <Wrapper>
      <Select label="Timezone" placeholder="Enter timezone" {...props}>
        {timezones.map((timezone, index) => {
          return (
            <option key={timezone} id={index.toString()} value={timezone}>
              {timezone}
            </option>
          );
        })}
      </Select>
      {name && timezone && (
        <TargetWrapper
          onClick={() => setFieldValue(name, timezone)}
          visible={timezone !== props.value}
        >
          <Target />
        </TargetWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const TargetWrapper = styled.div<{ visible?: boolean }>`
  border-radius: 50%;
  cursor: pointer;
  fill: #241f18;
  height: 1.25rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: absolute;
  right: 2.75rem;
  top: 1.35rem;
  transform: translateY(-50%);
  transition: all 0.2s ease-in;
  width: 1.25rem;
`;
