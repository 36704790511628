import { MessageTransport, UserProfile } from "@dailyhuman/web-core/dist/api/user";
import axios from "axios";
import { useQuery } from "react-query";
import { TProfile } from "types/user";
import { apiRoute, commonHeaders } from "utils/api";

import useIsAuth from "./useIsAuth";

export enum NotificationMethod {
  WHATSAPP = "WHATSAPP",
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export type ProfileData = UserProfile;

export default function useProfile() {
  const isAuth = useIsAuth();

  const { data, isFetching: isProfileLoading } = useQuery(
    ["getProfile", isAuth],
    loadProfile,
    {
      enabled: isAuth,
      refetchOnMount: false,
      onError: e => {
        console.error(e);
      },
    },
  );

  const profile = data?.data ? mapResponseToProfile(data.data) : undefined;

  return { profile: data?.data, isProfileLoading, newProfile: profile };
}

function loadProfile() {
  return axios.get<UserProfile>(`${apiRoute}/user/me`, commonHeaders());
}

const { EMAIL, SMS, WHATSAPP } = MessageTransport;

const mapResponseToProfile = (data: UserProfile): TProfile => ({
  email: data.email,
  phone: data.phone || "",
  firstName: data.firstName,
  lastName: data.lastName,
  timezone: data.timezone,
  isAdult: data.isAdult || false,
  notificationReceiveEmail: data.notificationMethod.includes(EMAIL),
  notificationReceiveSMS: data.notificationMethod.includes(SMS),
  notificationReceiveWhatsapp: data.notificationMethod.includes(WHATSAPP),
  parentsEmail: data.parentsEmail || "",
});
