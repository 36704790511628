import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute, commonHeaders } from "utils/api";

type TAnalyticsGroupPrompts = {
  data: {
    id: string;
    name: string;
  }[];
};

export default function useAnalyticsGroupPrompts(groupId: string) {
  const { data: response, isFetching: isAnalyticsGroupUsersLoading } = useQuery(
    ["useAnalyticsGroupUsers", groupId],
    () =>
      axios.get<TAnalyticsGroupPrompts>(
        `${apiRoute?.slice(0, -3)}/v2/prompt-set/by-coach`,
        {
          ...commonHeaders(),
          params: {
            hasAnalytics: true,
            groupId,
          },
        },
      ),
    { enabled: !!groupId },
  );

  return { isAnalyticsGroupUsersLoading, analyticsGroupPrompts: response?.data?.data };
}
