import axios from "axios";
import { useQuery } from "react-query";

import { apiRoute, commonHeaders } from "../utils/api";

export type CoachGroups = {
  id: string;
  name: string;
  children: CoachGroups;
}[];

function useGetCoachGroups() {
  const { data, isFetching: isCoachGroupsLoading } = useQuery(["useAnalytics"], () =>
    axios.get<CoachGroups>(`${apiRoute}/group/by-coach`, {
      ...commonHeaders(),
      params: {
        hasAnalytics: true,
      },
    }),
  );

  return { coachGroups: data?.data, isCoachGroupsLoading };
}

export default useGetCoachGroups;
