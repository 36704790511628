/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import { Timezone } from "@dailyhuman/web-core/dist/api/common";
import { Form, Formik, useFormikContext } from "formik";
import useIsAuth from "hooks/user/useIsAuth";
import { NotificationMethod } from "hooks/user/useProfile";
import { useRegisterUserToOnboardingPool } from "hooks/useRegisterUser";
import { emailValidation, phoneValidation } from "utils/validation";
import * as yup from "yup";

import { useOnboardingContext } from "./OnboardingUserFlow";

interface FormProps {
  email: string;
  parentsEmail: string;
  firstName: string;
  lastName: string;
  phone: string;
  preferredNotification: NotificationMethod[];
  timezone: string;
  isAdult?: boolean;
}

const initialValues: FormProps = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  preferredNotification: ["SMS"] as NotificationMethod[],
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York",
  isAdult: true,
  parentsEmail: "",
};

const RegisterForm = ({ children }: { children: React.ReactNode }) => {
  const isAuth = useIsAuth();
  const { sendRegisterRequest } = useRegisterUserToOnboardingPool();
  const { registerState } = useOnboardingContext() || {};

  const validationSchema =
    !isAuth &&
    yup.object({
      email: emailValidation,
      parentsEmail: emailValidation,
      firstName: yup.string().trim().required("Name is required").max(32),
      lastName: yup.string().trim().required("Last Name is required").max(32),
      phone: phoneValidation,
      timezone: yup.string().required("Required field").typeError("Required field"),
      preferredNotification: yup.array(),
      isAdult: yup.boolean().required("Required field"),
    });

  const handleSubmit = (values: FormProps) => {
    if (!registerState) return;
    const { smsCode, emailCode, whatsappCode } = registerState;
    sendRegisterRequest({
      firstName: values.firstName,
      lastName: values.lastName,
      email:
        values.email.length > 0
          ? {
              value: values.email,
              code: emailCode || "",
              notify: true,
            }
          : undefined,
      parentsEmail: values.isAdult === false ? values.parentsEmail : undefined,
      phone:
        smsCode || whatsappCode
          ? {
              value: values.phone,
              [registerState.smsCode ? "sms" : "whatsapp"]: {
                code: registerState.smsCode || registerState.whatsappCode,
                notify: true,
              },
            }
          : undefined,
      timezone: values.timezone as Timezone,
      isAdult: values.isAdult || false,
    });
  };

  return (
    <Formik
      validateOnMount={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export const useRegisterFormContext = () => useFormikContext<FormProps>();

export default RegisterForm;
