import Logo from "components/base/Logo";
import useProfile from "hooks/user/useProfile";
import styled from "styled-components";

import { ReactComponent as Analytics } from "./icons/Analytics.svg";
import { ReactComponent as GroupAnalytics } from "./icons/GroupAnalytics.svg";
import { ReactComponent as Journey } from "./icons/Journey.svg";
import { ReactComponent as Labs } from "./icons/Labs.svg";
import { ReactComponent as Profile } from "./icons/Profile.svg";
import MenuDrawerItem from "./MenuDrawerItem";

type BurgerMenuProps = {
  isVisible: boolean;
  logout: () => void;
};

export default function MenuDrawer({ isVisible, logout }: BurgerMenuProps) {
  const { profile } = useProfile();

  const routes = [
    {
      path: "/",
      title: "Journey Map",
      icon: Journey,
      color: "#664df3",
    },
    {
      path: "/labs?local=true",
      title: "Mind Labs",
      icon: Labs,
      color: "#49d3e6",
    },
    {
      path: "/analytics",
      title: "Insights",
      icon: Analytics,
      color: "#BCD61C",
    },
    ...(profile?.type === "COACH"
      ? [
          {
            path: "/analytics/group",
            title: "Group Analytics",
            icon: GroupAnalytics,
            color: "#BCD61C",
          },
        ]
      : []),
    {
      path: "/profile",
      title: "Account",
      icon: Profile,
      color: "#f43d54",
    },
  ];

  return (
    <Drawer isVisible={isVisible} className="MenuDrawer">
      <Header>
        <Logo />
      </Header>
      <NavBar>
        {routes.map(route => (
          <MenuDrawerItem
            key={route.path}
            title={route.title}
            path={route.path}
            icon={route.icon}
            color={route.color}
          />
        ))}
      </NavBar>
      <Footer>
        <div>
          <a href="https://www.dailyhuman.com/support" target="_blank" rel="noreferrer">
            <u>Support</u>
          </a>
        </div>
        <u onClick={logout}>Logout</u>
      </Footer>
    </Drawer>
  );
}

const Drawer = styled.div<{ isVisible: boolean }>`
  align-items: center;
  background: #ffffff;
  bottom: 0;
  color: #241f18;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1.5rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${({ isVisible }) =>
    isVisible ? "translate3d(0, 0, 0)" : "translate3d(100%, 0, 0)"};
  transition: transform 0.4s ease-in-out ${props => (props.isVisible ? "0.2s" : "0s")};
  width: 390px;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
`;

const NavBar = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 2rem 1rem;
`;

const Footer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  & u {
    color: #241f18;
    cursor: pointer;
  }
`;
