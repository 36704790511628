import Quote from "components/base/Quote";
import dayjs from "dayjs";
import styled from "styled-components";

interface MessageProps {
  isUserMessage: boolean;
  message: string;
}

export const Message = ({ isUserMessage, message }: MessageProps) => {
  return isUserMessage ? (
    <UserMessage message={message} />
  ) : (
    <ServerMessage message={message} />
  );
};

export const UserMessage = ({ message }: { message: string }) => {
  return <UserMessageWrapper>{message}</UserMessageWrapper>;
};

export const ServerMessage = ({ message }: { message: string }) => {
  const quoteRegex = /Here’s your answer so far:.*?[.!?]*/g;
  const match = message.match(quoteRegex);
  if (match) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, end] = message.split("Here’s your answer so far:");
    return (
      <ServerMessageWrapper>
        {"Here’s your answer so far:"}
        <ServerQuote>{end}</ServerQuote>
      </ServerMessageWrapper>
    );
  }
  return <ServerMessageWrapper>{message}</ServerMessageWrapper>;
};

export const HelloMessage = ({
  partnerName,
  end,
}: {
  partnerName: string;
  end: string;
}) => {
  const day = dayjs(end).isSame(dayjs(), "day")
    ? "today"
    : dayjs(end).isSame(dayjs().add(1, "day"), "day")
      ? "tomorrow"
      : // if less than 7 days from now, show weekday
        dayjs(end).isBefore(dayjs().add(7, "day"), "day")
        ? dayjs(end).format("dddd")
        : // Show mon day
          dayjs(end).format("MMM D");

  const hour = dayjs(end).format("h:mm A");

  return (
    <ServerMessageWrapper>
      <b>
        Respond by {day}, {hour}
      </b>
      <br />
      <br />
      {`Your reflection will be shared with ${partnerName}.`}
    </ServerMessageWrapper>
  );
};

const ServerQuote = styled(Quote)`
  margin: 0 1rem 0 auto;
  max-width: 80%;
  @media (min-width: 768px) {
    max-width: 70%;
  }
`;

const UserMessageWrapper = styled(ServerQuote)`
  > svg {
    display: none;
  }
`;

export const ServerMessageWrapper = styled.div`
  color: #676464;
  margin-bottom: 2rem;
  max-width: 800px;
  padding: 1rem;
  white-space: pre-line;
  & > div {
    margin: 1rem 0 -2rem;
    position: relative;
  }
  b {
    font-weight: 600;
  }
  & > div > svg {
    left: -10px;
    position: absolute;
    top: -10px;
  }
`;
