import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useIntersectionObserver } from "usehooks-ts";

interface IntersectionObserverProps {
  onIntersection?: () => void;
  children: React.ReactNode;
}

const IntersectionObserver = ({onIntersection, ...props}: IntersectionObserverProps) => {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const entry = useIntersectionObserver(ref, { rootMargin: "-50%" });

  useEffect(() => {
    if (entry?.isIntersecting && !isIntersecting) {
      setIsIntersecting(true);
      onIntersection?.();
    } else if (!entry?.isIntersecting && isIntersecting) {
      setIsIntersecting(false);
    }
  }, [entry?.isIntersecting, isIntersecting, onIntersection]);

  return <Wrapper {...props} ref={ref} isIntersecting={isIntersecting} />;
}

const Wrapper = styled.div<{ isIntersecting: boolean }>`
  padding-top: 10rem;
  transform: ${({isIntersecting}) => (isIntersecting ? "none" : "translateY(1rem)")};
  transition: all 0.8s ease-out 0.1s;
  &:first-child {
    padding-top: 0;
  }
`;

export default IntersectionObserver;