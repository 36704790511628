import Accordion from "components/animations/Accordion";
import Fade from "components/animations/Fade";
import Button from "components/form/Button";
import { useLabTermination } from "hooks/labs/useLabTermination";
import DelayedRedirect from "pages/Labs/DelayedRedirect";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BrowserStorageItem, localStorageService } from "utils/StorageHelper";

import SendButton from "./SendButton";

interface MessagingInputProps {
  message: string;
  isDisabled?: boolean;
  handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleSendMessage: () => void;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  gameCycleId: string;
  isMoreThanOneUserMessage: boolean;
  gameId: string;
  isAddingMessage: boolean;
  isLastMessageFromUser: boolean;
}

const MessagingInput = ({
  message,
  handleInputChange,
  handleSendMessage,
  gameCycleId,
  isMoreThanOneUserMessage,
  isDisabled = false,
  gameId,
  isAddingMessage,
  isLastMessageFromUser,
}: MessagingInputProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const { closeLab } = useLabTermination();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  useEffect(() => {
    const storedRedirectTime = localStorageService.get(
      BrowserStorageItem.REDIRECT_TIME,
    );
    if (storedRedirectTime && new Date(storedRedirectTime) > new Date()) {
      setShouldRedirect(true);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transition = "none";
      const currentHeight = ref.current.clientHeight;
      ref.current.style.height = "1px";
      const scrollHeight = ref.current.scrollHeight;
      ref.current.style.height = currentHeight + "px";
      if (scrollHeight !== currentHeight) {
        setTimeout(() => {
          if (!ref?.current) return;
          ref.current.style.transition = "height 0.3s";
          ref.current.style.height = scrollHeight + "px";
        }, 1);
      }
    }
  }, [ref, message]);

  const handleSendClick = () => {
    if (!disabled) {
      handleSendMessage();
    }
  };

  const delay = 20;
  const limit = 1500;
  const remaining = limit - message.length;
  const disabled = message.trim() === "" || isDisabled || remaining < 0;

  const handleLabTerminate = () => {
    closeLab({ cycleId: gameCycleId });
    closeModal();
    setShouldRedirect(true);
    localStorageService.set(
      BrowserStorageItem.REDIRECT_TIME,
      new Date(new Date().getTime() + delay * 1000).toISOString(),
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="MessagingInput">
      <Wrapper>
        <Accordion>
          {message.length === 0 &&
            isMoreThanOneUserMessage &&
            !isAddingMessage &&
            !isLastMessageFromUser && (
              <Fade>
                <ButtonWrapper>
                  <StyledButton onClick={() => setIsModalOpen(true)}>
                    Finish this session
                  </StyledButton>
                </ButtonWrapper>
              </Fade>
            )}
        </Accordion>
        <InputFieldContainer>
          <InputField
            placeholder={
              isMoreThanOneUserMessage ? "Keep reflecting..." : "Start reflecting..."
            }
            value={message}
            onChange={handleInputChange}
            ref={ref}
          />
          <SendButtonContainer isDisabled={disabled} onClick={handleSendClick}>
            <SendButton isDisabled={disabled} />
          </SendButtonContainer>
        </InputFieldContainer>
        <ErrorContainer remaining={remaining}>
          {message.length} / {limit}
        </ErrorContainer>
      </Wrapper>
      <Modal visible={isModalOpen}>
        <Fade visible={isModalOpen}>
          <ModalContent>
            <ModalTextContainer>
              <BoldText>All set?</BoldText>
              <p>
                Finishing this session will save your reflection and reveal your
                insights.
              </p>
            </ModalTextContainer>
            <ButtonsContainer>
              <StyledButton onClick={handleLabTerminate}>
                Finish this session
              </StyledButton>
              <SecondaryButton onClick={closeModal}>Keep reflecting</SecondaryButton>
            </ButtonsContainer>
          </ModalContent>
        </Fade>
      </Modal>
      {shouldRedirect && <DelayedRedirect delay={delay} to={`/analytics/${gameId}`} />}
    </div>
  );
};

export default MessagingInput;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  position: relative;
`;

const Modal = styled.div<{ visible?: boolean }>`
  background: #f8f7f0;
  height: 100dvh;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  padding: 2rem;
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  position: fixed;
  text-align: center;
  top: 0;
  width: 100dvw;
  z-index: 101;
`;

const BoldText = styled.p`
  font-weight: 800;
`;

const StyledButton = styled(Button)`
  margin-bottom: 1rem;
  width: max-content;
`;

const SecondaryButton = styled(StyledButton)`
  background: #eee7df;
  color: #664df3;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15% auto;
`;

const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const InputFieldContainer = styled.div`
  align-items: center;
  background: #fff;
  border: none;
  border-radius: 1.625rem;
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
`;

const InputField = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  color: #241f18;
  font-family: inherit;
  font-size: 1.125rem;
  height: 2rem;
  line-height: 150%;
  max-height: 10rem;
  outline: none;
  overflow-y: scroll;
  resize: none;
  width: 100%;
  ::placeholder {
    color: #bfae96;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SendButtonContainer = styled.div<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  height: 2rem;
  width: 2rem;
`;

const ErrorContainer = styled.div<{ remaining: number }>`
  bottom: 0;
  color: ${({ remaining }) => (remaining < 0 ? "#B81822" : "auto")};
  opacity: ${({ remaining }) => (remaining < 500 ? 1 : 0)};
  padding: 0.35rem 1rem;
  position: absolute;
  right: 0.5rem;
  text-align: right;
  transition: all 0.3s;
`;
