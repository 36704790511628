import axios, { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation } from "react-query";

import { SnackBarContext } from "../../components/base/Alert/Alert";
import { apiRoute, commonHeaders } from "../../utils/api";

type MutateDataType = {
  cycleId: string;
};

function useLabTermination() {
  const context = useContext(SnackBarContext);
  const {
    mutate: closeLab,
    isLoading: isLabClosing,
    isError,
  } = useMutation(
    (data: MutateDataType) =>
      axios.post<{ scheduledCompleteAt: string }>(
        `${apiRoute}/game/cycle/${data.cycleId}/terminate`,
        undefined,
        commonHeaders(),
      ),
    {
      onError: (error: AxiosError) =>
        context?.setSnackBar({
          status: "failure",
          text: error.message,
        }),
    },
  );

  return {
    closeLab,
    isLabClosing,
    isError,
  };
}

export { useLabTermination };
