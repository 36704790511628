import axios from "axios";
import { useQuery } from "react-query";
import { apiRoute, commonHeaders } from "utils/api";

export default function useAnalyticsGroupUserLabs(userId?: string) {
  const { data, isFetching: isLabsLoading } = useQuery(
    ["useAnalyticsGroupUserLabs", userId],
    () =>
      axios.get<any>(`${apiRoute}/game/by-user`, {
        ...commonHeaders(),
        params: {
          userId,
        },
      }),
    { enabled: !!userId },
  );

  const labs = data?.data?.data;

  return { labs, isLabsLoading };
}
